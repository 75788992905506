<template>
  <div>
    <div v-for="items in group" :key="items.name">
      <van-row>
        <van-col :span="22" :offset="1">
          <van-row>
            <van-col :span="24">
              <van-field v-model="items.title" :required="items.required" rows="1" autosize type="textarea" readonly></van-field>
              <!--                  <span style="color: red;font-size: 18px;" v-if="items.required==true">*</span><span>{{items.title}}</span>-->
            </van-col>
          </van-row>
          <div v-if="items.type=='txt'">
            <van-field v-if="items.required == true" v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入"
                       clearable :rules="[{required:true,message:'必填'}]" autosize type="textarea"></van-field>
            <van-field v-else v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入"
                       autosize type="textarea" clearable></van-field>
          </div>
          <div v-else-if="items.type=='number'">
            <van-field v-if="items.required == true" type="digit" v-model="ruleForm[items.name]" maxlength="10"
                       placeholder="请输入整数" :rules="[{required:true,message:'必填'}]" clearable></van-field>
            <van-field v-else type="digit" v-model="ruleForm[items.name]" maxlength="10" placeholder="请输入整数" clearable></van-field>
          </div>
          <div v-else-if="items.type=='decimal'">
            <van-field v-if="items.required == true" type="number" v-model="ruleForm[items.name]" maxlength="10"
                       placeholder="请输入一个数（可小数）" :rules="[{required:true,message:'必填'}]" clearable></van-field>
            <van-field v-else type="number" v-model="ruleForm[items.name]" maxlength="10" placeholder="请输入一个数（可小数）" clearable></van-field>
          </div>
          <div v-else-if="items.type=='phone'">
            <van-field v-if="items.required == true" type="tel" v-model.number="ruleForm[items.name]" maxlength="11"
                       placeholder="请输入电话号码" :rules="[{ required: true, message: '必填' },{pattern,message: '电话号码输入有误'}]" clearable></van-field>
            <van-field v-else type="tel" v-model.number="ruleForm[items.name]" maxlength="11" placeholder="请输入电话号码"
                       clearable :rules="[{ pattern,message: '电话号码输入有误'}]"></van-field>
          </div>
          <div v-else-if="items.type=='date'">
            <van-field v-if="items.required == true" v-model="ruleForm[items.name]" @click="opentime(items.name,1)"
                       placeholder="请选择日期" :rules="[{ required: true, message: '必填' }]"></van-field>
            <van-field v-else v-model="ruleForm[items.name]" @click="opentime(items.name,1)" placeholder="请选择日期"></van-field>
          </div>
          <div v-else-if="items.type=='time'">
            <van-field v-if="items.required == true" v-model="ruleForm[items.name]" @click="opentime(items.name,2)"
                       placeholder="请选择时间" :rules="[{ required: true, message: '必填' }]"></van-field>
            <van-field v-else v-model="ruleForm[items.name]" @click="opentime(items.name,2)" placeholder="请选择时间"></van-field>
          </div>
          <div v-else-if="items.type=='datetime'">
            <van-field v-if="items.required == true" v-model="ruleForm[items.name]" @click="opentime(items.name,3)"
                       placeholder="请选择日期时间" :rules="[{ required: true, message: '必填' }]"></van-field>
            <van-field v-else v-model="ruleForm[items.name]" @click="opentime(items.name,3)" placeholder="请选择日期时间"></van-field>
          </div>
          <!-- 单选 -->
          <div v-else-if="items.type=='select'">
            <div v-if="items.source.data.length!=0">
              <!-- 判断是否必填 -->
              <van-field :name="items.name" v-if="items.required == true " :rules="[{ required: true, message: '请选择一项' }]">
                <template #input>
                  <van-radio-group v-model="ruleForm[items.name]" direction="horizontal">
                    <van-radio v-for="(i,k) in items.source.data" :key="k" :name="i.value" class="mt1">{{i.label}}</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field :name="items.name" v-else>
                <template #input>
                  <van-radio-group v-model="ruleForm[items.name]" direction="horizontal">
                    <van-radio v-for="(i,k) in items.source.data" :key="k" :name="i.value" class="mt1">{{i.label}}</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
            <div v-else>
              <van-field v-if="items.required == true" v-model="ruleForm[items.name].label" :rules="[{ required: true, message: '必填' }]" disabled></van-field>
              <van-field v-else v-model="ruleForm[items.name].label" disabled></van-field>
            </div>
          </div>
          <!-- 多选-->
          <div v-else-if="items.type == 'mutiselect'">
            <div v-if="items.source.data.length!=0">
              <van-field :name="items.name" v-if="items.required == true" :rules="[{ required: true, message: '请至少选择一项' }]">
                <template #input>
                  <van-checkbox-group v-model="ruleForm[items.name]" direction="horizontal">
                    <van-checkbox v-for="(ite,kee) in items.source.data" :key="kee" :name="ite.value" class="mt1" shape="square">
                      {{ ite.label }}
                    </van-checkbox>
                  </van-checkbox-group>
                </template>
              </van-field>
              <van-field :name="items.name" v-else>
                <template #input>
                  <van-checkbox-group v-model="ruleForm[items.name]" direction="horizontal">
                    <van-checkbox v-for="(ite,kee) in items.source.data" :key="kee" :name="ite.value" class="mt1" shape="square">
                      {{ ite.label }}
                    </van-checkbox>
                  </van-checkbox-group>
                </template>
              </van-field>
            </div>
            <div v-else>
              <van-field v-if="items.required == true" v-model="ruleForm[items.name]" :rules="[{ required: true, message: '必填' }]" disabled>
                <template #input>
                  <span v-for="iss in ruleForm[items.name]" :key="iss.value">{{ iss.label }}，</span>
                </template>
              </van-field>
              <van-field v-else v-model="ruleForm[items.name].label" disabled>
                <span v-for="iss in ruleForm[items.name]" :key="iss.value">{{ iss.label }}，</span>
              </van-field>
            </div>
          </div>
          <div v-else-if="items.type == 'img'||items.type == 'file'">
            <van-cell v-if="ruleForm[items.name]!=null&&ruleForm[items.name]!=''&&ruleForm[items.name]!=undefined">
              <template #extra>
                <van-image width="3rem" height="4rem" v-for="(it,k) in ruleForm[items.name].list" :key="k"
                           :src="it" class="mr5" @click="showimg(ruleForm[items.name].list)">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="16"/>
                  </template>
                </van-image>
              </template>
            </van-cell>
            <van-cell v-else></van-cell>
          </div>
          <div v-else>
            <van-field v-if="items.required == true" v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入"
                       clearable :rules="[{required:true,message:'必填'}]"></van-field>
            <van-field v-else v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入" clearable></van-field>
          </div>
        </van-col>
      </van-row>
      <div class="line1"></div>
    </div>
    <!--   日期、时间弹出框-->
    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }" >
      <van-datetime-picker v-model="currentDate" type="date"  title="选择年月日"  @confirm="getDate" @cancel="cancel"/>
    </van-popup>
    <van-popup v-model="show1" position="bottom" :style="{ height: '50%' }" >
      <van-datetime-picker v-model="currentTime" type="time"  title="选择时间"  @confirm="getTime" @cancel="show1 = false"/>
    </van-popup>
    <van-popup v-model="show2" position="bottom" :style="{ height: '50%' }" >
      <van-datetime-picker v-model="currentDateTime" type="datetime"  title="选择日期时间"  @confirm="getDateTime" @cancel="show2 = false"/>
    </van-popup>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {ImagePreview} from "vant";
export default {
  name: "formDisplay",
  mixins: [mixins],
  props:['group','ruleForm'],
  data(){
    return{
      date:"",//日期年月日
      show:false,//
      time:"",//时间
      show1:false,//
      datetime:"",//日期时间
      show2:false,
      currentDate: new Date(),
      currentTime:"",
      currentDateTime: new Date(),
      timeitem:"",//
      pattern:/^[1][3,4,5,7,8][0-9]{9}$/,
    }
  },
  mounted() {
    this.formatTime();
    console.log("ruleform-sssss",this.ruleForm)
  },
  methods:{

    getDate(value){
      this.ruleForm[this.timeitem]=this.formatDate(value);
      this.show=false;
    },
    getTime(times){
      this.ruleForm[this.timeitem] = times;
      this.show1 = false;
    },
    getDateTime(datetime){
      this.ruleForm[this.timeitem] = this.formatDateTime(datetime);
      this.show2 = false;
    },
    cancel(){
      this.show=false;
    },
    opentime(item,n){
      this.timeitem=item;
      if (n == 1){
        this.show = true;
      }else if (n == 2){
        this.show1 = true;
      }else if (n == 3){
        this.show2 = true;
      }
    },
    showimg(img) {//图片预览
      ImagePreview({
        images: img,
        closeable: true,
      });
    },
  }
}
</script>

<style scoped>
.padd5{padding: 5px 0;}
.wid{width: 100%;}
.mt1{margin-top: 5px;}
.mb1{margin-bottom: 5px;}
.mt3{margin-top: 3vh;}
.mt4{margin-top: 4vh;}
.mt5{margin-top: 5vh;}
.mt20{margin-top: 2vh;}
.mt10{margin-top: 10px;}
.ml15{margin-left: 15px;}
.mr5{margin-right: 5px;}
.mb20{margin-bottom: 2vh;}
.mt15{margin-top: 15px;}
.mb10{margin-bottom: 1vh;}
.font13{font-size: 13px;}
.line1{border-top: #f2f2f2 solid 1px;width: 88vw;margin: 8px auto;}
.line{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 20px auto;}
.mr{margin-right: 18vw;}
.mr10{margin-right: 10px;}
.mright{text-align:right;}
.mcenter{text-align: center;}
.btn{text-align: center;margin: 4vh auto;}
.txt{vertical-align: middle;}
/deep/ .van-dropdown-menu{box-shadow:0 0 0 #f2f2f2!important;}
/deep/ .van-cell{padding: 4px 10px;}
.col19{color: #1989f0;}
.van-radio__label--disabled {color: #333!important;}
</style>