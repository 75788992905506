<template>
  <div>
    <van-nav-bar title="编辑" :border="false">
      <template #left>
        <van-icon name="arrow-left" color="#000000" size="18" @click="onClickLeft"/>
      </template>
      <template #right>
        <span @click="save" class="col19">完成</span>
      </template>
    </van-nav-bar>
    <form-display :group="group.fields" :ruleForm="ruleForm"></form-display>
  </div>
</template>

<script>
//列表数据添加界面
import formDisplay from "@/components/formDisplay";
export default {
  name: "addList",
  props: ['group','ruleForm'],
  components: {
    formDisplay,
  },
  data(){
    return{
      //ruleForm: {},
      listinfo: [],
      isupdate: false,//是否为修改
      key: "",
    }
  },
  created() {
    if (localStorage.getItem('listinfo')){
      this.listinfo = JSON.parse(localStorage.getItem('listinfo'));
    }
  },
  mounted() {
    this.key = this.$store.getters.addinfo.key;
  },
  methods:{
    save(){
      //console.log("addrule",this.ruleForm);
      if (this.key != null && this.key != ''){
        this.listinfo[this.key] = this.ruleForm;
      }else {
        this.listinfo.push(this.ruleForm);//console.log("add",this.listinfo);
      }
      localStorage.removeItem('listinfo');
      let data = JSON.stringify(this.listinfo)
      localStorage.setItem('listinfo',data);
      //this.$store.commit('addlist/SET_ADDINFO',this.listinfo);
      //console.log("addrule",localStorage.getItem('listinfo'));
      this.$emit('changedis',0);
    },
    onClickLeft(){
      this.$emit('changedis',0);
    },
    initData(){
      this.isupdate = false;
    }
  },
}
</script>

<style scoped>
.col19{color: #1989f0;}
</style>