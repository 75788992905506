import Api from '@/utils/request';
import {token} from "@/utils/utils";

/**
 * 获取json表单格式
 *
 * @export
 */

/**
 * 提交表单数据
 *
 * @export
 */
export function submitForm(data) {
    return Api('/api/v1/share/save', 'post', data,false,{"token":token()});
}

/**
 * 获取已绑定的手机号具体信息
 *
 * @export
 */
export function gainBindPhoneNum(code) {
    return Api('/sso/auth/bindingwx/'+code, 'get', {},false);
}

/**
 * 获取短信验证码,
 *
 * @export
 */
export function SMSverification(data) {
    return Api('/sso/auth/bindingsms', 'post', data,false);
}

/**
 * 提交数据绑定微信
 *
 * @export
 */
export function postBindInfo(data) {
    return Api('/sso/auth/bindingwx', 'post', data,false);
}

/**
 * 查询分享数据
 *
 * @export
 */
export function getShareData(shareId) {
    return Api('/pub/share/data/'+shareId, 'get', {},false);
}

/**
 * 查询可以编辑的分享数据
 *
 * @export
 */
export function getEditData(shareId) {
    return Api('/pub/share/editdata/'+shareId, 'get', {},false);
}

/**
 * 保存修改后的分享的数据
 *
 * @export
 */
export function saveShareData(shareId,data) {
    return Api('/pub/share/save/'+shareId, 'post', data,false);
}


