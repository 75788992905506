export function token(){
    let token = "";
    try {
        let code = localStorage.getItem("token");
        if (code){
            token = code;
        }
        return token;
    }catch (e) {
        return token;
    }
}